.better-bubbles {
  //border: 2px solid yellow;
  height: 100%;
  .row {
    //border: 3px solid red;
    min-height: 38vh;
    &.row1 {
      align-content: end;
      .f1 {
        margin-top:33%;
      }
      .f3 {
        //margin-top:15%;
      }
    }
    &.row2 {
      align-content: start;
      .f2 {
        margin-left: 23%;
      }
      .f4 {
        margin-top: 23%;
        margin-left: 23%;
      }
    }
    div {
      //border: 3px solid green;
      //height: 100%;
    }
  }
  @media screen and (max-width: 550px) {
    display: none;
  }
}