
//-------------------- Fonts --------------------//

$body-font: 'Lato', sans-serif;
$title-font: 'Lato', sans-serif;

//-------------------- Base Colors --------------------//

$primary-color: 			#ffffff;
$primary-color-hover: 		darken($primary-color, 7%);
$secondary-color: 			#939597;
$body-bg:					#dddddd;
$orange:                    #f38a4d;
$altin-green:               #B0D3AE;
$altin-dark-green:          #6D9895;
$altin-blue:                #47A7DF;
$altin-off-white:           #FAFAF8;
$altin-dark-gray:           #4B4B4B;
$altin-orange:              #F38A4D;

//-------------------- Social Icons Colors --------------------//

$facebook:                 #3B5998;
$twitter:                  #00ACEE;
$google:                   #DD4B39;
$linkedin:                 #0E76A8;
$youtube:                  #C4302B;
$instagram:                #3F729B;
$dribbble: 				   #ea4c89;
$github: 				   #333333;
$behance: 				   #053eff;
$reddit:                   #C6C6C6;
$pinterest: 			   #cc2127;
$vk:                       #2B587A;
$rss:                      #EE802F;
$skype:                    #00AFF0;
$xing:                     #126567;
$tumblr:                   #34526F;
$email:                    #6567A5;
$delicious:                #205CC0;
$stumbleupon:              #F74425;
$digg:                     #191919;
$blogger:                  #FC4F08;
$flickr:                   #FF0084;
$vimeo:                    #86C9EF;
$yahoo:                    #720E9E;
$appstore:                 #000;